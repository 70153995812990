<template>
  <div class="page1">
    <div class="mb20">
      <el-button type="primary" size="small" @click="showDialog">新增</el-button>
      <el-button type="danger" size="small" plain @click="deleteMore">删除</el-button>
    </div>
    <el-table
      :header-cell-style="{ background: '#f4f4f5' }"
      tooltip-effect="dark"
      style="width: 100%; margin-bottom: 20px; margin-top: 20px"
      :data="tableData"
      size="small"
      row-key="id"
      border
      lazy
      :tree-props="{ children: 'childrens', hasChildren: 'hasChildren' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        v-for="(item, index) in tableTitle"
        :key="index"
        :align="item.align"
        :width="item.width"
      >
        <template slot-scope="scope">
          <span v-if="item.prop == 'source'">
            <i :class="scope.row.source" class="fs18"></i>
          </span>
          <span v-else-if="item.prop == 'category'">
            {{ scope.row.category == 1 ? '菜单' : '按钮' }}
          </span>
          <span v-else-if="scope.row[item.prop] === ''">-</span>
          <span v-else>{{ scope.row[item.prop] }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <div>
            <e-button type="text" @click="checkInfo(scope.row, 0)">修改</e-button>
            <e-button type="text" @click="deleteInfo(scope.row)">删除</e-button>

            <el-button type="text" @click="addSubMenu(scope.row, 1, scope.$index)" v-if="scope.row.parentId != 0 && scope.row.category == 1"
              >增加按钮
            </el-button>
            <el-button type="text" @click="addSubMenu(scope.row, 2)" v-if="scope.row.parentId == 0">增加子项 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="closeDialog" width="60%" @handleClick="addMenu">
      <el-form label-position="right" label-width="100px" :model="form" :rules="formRules" ref="form">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上级菜单" prop="parentId">
              <el-cascader
                placeholder="请选择上级菜单"
                :options="options"
                :props="{ checkStrictly: true }"
                clearable
                filterable
                v-model="form.parentId"
                @change="getParent"
                style="width: 100%"
                :disabled="disabled"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单名称" prop="name">
              <el-input v-model="form.name" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单别名" prop="code">
              <el-input v-model="form.code" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="路由地址" prop="path">
              <el-input v-model="form.path" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单图标" prop="source">
              <el-input v-model="form.source" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单排序" prop="sort">
              <el-input v-model="form.sort" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单类型" prop="category" @change="changeMenuType">
              <el-radio :label="1" v-model="form.category" :disabled="disabled">菜单 </el-radio>
              <el-radio :label="2" v-model="form.category" :disabled="disabled">按钮 </el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="form.remark" type="textarea" :disabled="disabled"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </EDialog>
  </div>
</template>

<script>
  import Http from '@/service/http';
  import EButton from '@/components/EButton';
  import ETable from '@/components/ETable';
  import EDialog from '@/components/EDialog';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';

  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '菜单名称',
            prop: 'name',
            width: '150px',
          },
          {
            label: '路由地址',
            prop: 'path',
          },
          {
            label: '菜单图标',
            prop: 'source',
            align: 'center',
          },
          {
            label: '菜单别名',
            prop: 'code',
          },
          {
            label: '菜单类型',
            prop: 'category',
          },
          {
            label: '菜单排序',
            prop: 'sort',
          },
        ],
        tableData: [],
        form: {
          category: 1, //菜单类型
          code: '', //菜单别名
          id: '', //主键
          name: '', //菜单名称
          parentId: '', //菜单父主键
          path: '', //请求地址
          remark: '', //备注
          sort: '', //排序
          source: '', //菜单资源
        },
        title: '新增',
        dialogVisible: false,
        formRules: {
          // code: vxRule(true, '', "blur", "菜单别名不能为空"),
          name: [vxRule(true, '', 'blur', '菜单名称不能为空')],
          // parentId: vxRule(true, '', "change", "上级菜单不能为空"),
          sort: vxRule(true, '', 'blur', '排序不能为空'),
          // path: [vxRule(true, '', "blur", "路由地址不能为空")],
          category: vxRule(true, '', 'blur', '菜单类型不能为空'),
        },
        options: [],
        selectRoutes: [],
        disabled: false,
        iconDialog: false,
      };
    },
    watch: {
      $route: {},
    },
    components: { EButton, ETable, EDialog, EForm },
    created() {
      this.getData();
      this.parentRoutes();
    },
    beforeMount() {},
    methods: {
      changeMenuType() {
        if (flag == 1) {
          //增加按钮
          this.formRules = {
            name: vxRule(true, '', 'blur', '菜单名称不能为空'),
            sort: vxRule(true, '', 'blur', '排序不能为空'),
          };
          this.form.category = 2;
        }
      },
      addSubMenu(data, flag, index) {
        console.log(index);
        if (flag == 1) {
          //增加按钮
          this.formRules = {
            name: vxRule(true, '', 'blur', '菜单名称不能为空'),
            sort: vxRule(true, '', 'blur', '排序不能为空'),
          };
          this.form.category = 2;
          //两级菜单
          let arr = [],
            id = [];
          //获取对应的键
          this.getParentId(arr, data.id, this.tableData);
          if (arr.length > 0) {
            id.push(this.tableData[arr[0]].id);
            if (arr.length > 1) {
              id.push(this.tableData[arr[0]].childrens[arr[1]].id);
            }
          }
          this.form.parentId = [...id, data.id];
        } else {
          //单级菜单
          this.form.parentId = data.id;
        }
        this.dialogVisible = true;
      },
      handleSelectionChange(data) {
        console.log(data);
        if (data.length > 0) {
          data.forEach((item) => {
            this.selectRoutes.push(item.id);
          });
        }
      },
      deleteMore() {
        this.deleteInfo(this.selectRoutes.toString());
      },
      deleteInfo(data) {
        this.$messageBox
          .confirm(`确定删除该菜单吗？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then((res) => {
            this.removeRoute(data.id);
          })
          .catch((res) => {});
      },
      getParentId(parentId, id, arr) {
        arr.forEach((item, i) => {
          if (item.childrens && item.childrens.length > 0) {
            item.childrens.forEach((sub, j) => {
              if (id == sub.id) {
                parentId.push(i);
              }
              if (sub.childrens && sub.childrens.length > 0) {
                sub.childrens.forEach((least) => {
                  if (id == least.id) {
                    parentId.push(i, j);
                  }
                });
              }
            });
          }
        });
      },
      checkInfo(data, flag) {
        this.dialogVisible = true;
        if (flag == 1) {
          this.disabled = true;
        }
        //两级菜单
        let arr = [];
        //获取对应的键
        this.getParentId(arr, data.id, this.tableData);
        //
        let id = [];
        if (arr.length > 0) {
          id.push(this.tableData[arr[0]].id);
          if (arr.length > 1) {
            id.push(this.tableData[arr[0]].childrens[arr[1]].id);
          }
        }
        this.form = {
          category: data.category, //菜单类型
          code: data.code, //菜单别名
          id: data.id, //主键
          name: data.name, //菜单名称
          parentId: id, //菜单父主键
          path: data.path, //请求地址
          remark: data.remark, //备注
          sort: data.sort, //排序
          source: data.source, //菜单资源
        };
        this.title = '修改';
        if (this.form.category == 2) {
          this.formRules = {
            name: vxRule(true, '', 'blur', '菜单名称不能为空'),
            sort: vxRule(true, '', 'blur', '排序不能为空'),
          };
        }
      },
      closeDialog() {
        this.$refs['form'].resetFields();
        this.dialogVisible = false;
        this.form = {
          category: 1, //菜单类型
          code: '', //菜单别名
          id: '', //主键
          name: '', //菜单名称
          parentId: '', //菜单父主键
          path: '', //请求地址
          remark: '', //备注
          sort: '', //排序
          source: '', //菜单资源
        };
        this.title = '增加';
        this.disabled = false;
      },
      showDialog() {
        this.dialogVisible = true;
      },
      getParent() {},
      editDialog() {},
      async addMenu() {
        this.$refs['form'].validate((valid, object) => {
          console.log(object);
          if (valid && !this.error) {
            this.menuSubmit();
          } else {
            return false;
          }
        });
      },
      checkSelectable() {},
      // load(tree, treeNode, resolve) {
      //   setTimeout(() => {
      //     resolve([
      //       {
      //         id: 1,
      //         route: '/desk',
      //         number: 'desk',
      //         name: 'Desk',
      //         sort: '1'
      //       }, {
      //         id: 1,
      //         route: '/desk',
      //         number: 'desk',
      //         name: 'Desk',
      //         sort: '1'
      //       }
      //     ])
      //   }, 1000)
      // },
      async menuSubmit() {
        let { category, code, id, name, parentId, path, remark, sort, source } = this.form;
        console.log(typeof parentId);
        let res = await Http.menuSubmit({
          category,
          code,
          id,
          name,
          parentId: typeof parentId == 'object' ? parentId[parentId.length - 1] : parentId,
          path,
          remark,
          sort,
          source,
        });
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.closeDialog();
          this.getData();
        }
      },
      async getData() {
        let res = await Http.routes();
        if (res.code == 200) {
          this.tableData = res.data;
        }
      },
      async parentRoutes() {
        let res = await Http.parentRoutes();
        if (res.code == 200) {
          this.options = res.data;
        }
      },
      async removeRoute(ids) {
        let res = await Http.removeRoute({ ids });
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getData();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-cascader {
  width: 100%;
}

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: #999;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #fff;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: #2d3a4b;
    cursor: pointer;
    user-select: none;
  }
}
</style>
